
// App.js
import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function App() {
  // Required inputs
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(7.5);
  const [loanTenure, setLoanTenure] = useState(60);
  const [startDate, setStartDate] = useState(new Date());
  const [originalSchedule, setOriginalSchedule] = useState(null);

  // Optional recurring payment inputs
  const [enableRecurring, setEnableRecurring] = useState(false);
  const [recurringAmount, setRecurringAmount] = useState(1000);
  const [recurringFrequency, setRecurringFrequency] = useState('Monthly');
  const [recurringStartDate, setRecurringStartDate] = useState(new Date());

  const [recurringPayments, setRecurringPayments] = useState([
    { 
      amount: 1000,
      frequency: 'Monthly',
      startDate: new Date()
    }
  ]);

  // Optional adhoc payments
  const [enableAdhoc, setEnableAdhoc] = useState(false);
  const [adhocPayments, setAdhocPayments] = useState([
    { date: new Date(), amount: 1000 },
  ]);

  // Results
  const [schedule, setSchedule] = useState(null);
  const [expandedYears, setExpandedYears] = useState({});

  const toggleYearExpansion = (year) => {
    setExpandedYears(prev => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  const calculateEMI = (principal, rate, tenure) => {
    const monthlyRate = rate / (12 * 100);
    return (
      (principal *
        monthlyRate *
        Math.pow(1 + monthlyRate, tenure)) /
      (Math.pow(1 + monthlyRate, tenure) - 1)
    );
  };

  const processPrePayments = (startDate, endDate) => {
    const prepayments = {};
    
    if (enableRecurring) {
      recurringPayments.forEach(recurringPayment => {
        let currentDate = new Date(recurringPayment.startDate);
        const frequencyMonths = {
          Monthly: 1,
          Quarterly: 3,
          Yearly: 12,
        };
        
        while (currentDate <= endDate) {
          if (currentDate >= startDate) {
            const dateKey = currentDate.toISOString().slice(0, 7);
            prepayments[dateKey] = (prepayments[dateKey] || 0) + recurringPayment.amount;
          }
          currentDate.setMonth(
            currentDate.getMonth() + frequencyMonths[recurringPayment.frequency]
          );
        }
      });
    }
  
    if (enableAdhoc) {
      adhocPayments.forEach(({ date, amount }) => {
        const dateKey = date.toISOString().slice(0, 7);
        if (date >= startDate && date <= endDate) {
          prepayments[dateKey] = (prepayments[dateKey] || 0) + amount;
        }
      });
    }
  
    return prepayments;
  };

  const generateSchedule = () => {
    // Calculate original schedule without prepayments
    const originalSchedule = generateAmortizationSchedule(false);
    setOriginalSchedule(originalSchedule);
    
    // Calculate schedule with prepayments
    const scheduleWithPrepayments = generateAmortizationSchedule(true);
    setSchedule(scheduleWithPrepayments);
  };
  
  const generateAmortizationSchedule = (includePrePayments) => {
    const monthlyRate = interestRate / (12 * 100);
    const emi = calculateEMI(loanAmount, interestRate, loanTenure);
    
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + loanTenure);
    
    const prepayments = includePrePayments ? processPrePayments(startDate, endDate) : {};
    
    let remainingBalance = loanAmount;
    let totalPrincipal = 0;
    let totalInterest = 0;
    const schedule = [];
    
    let currentDate = new Date(startDate);
    
    while (remainingBalance > 0 && schedule.length <= loanTenure) {
      const dateKey = currentDate.toISOString().slice(0, 7);
      const interest = remainingBalance * monthlyRate;
      const principal = Math.min(emi - interest, remainingBalance);
      const prepayment = prepayments[dateKey] || 0;
      
      const payment = principal + interest + prepayment;
      remainingBalance -= (principal + prepayment);
      
      totalPrincipal += principal + prepayment;
      totalInterest += interest;
      
      const loanPaidPercentage = (totalPrincipal / loanAmount) * 100;
      
      schedule.push({
        id: schedule.length,
        date: dateKey,
        principal: principal.toFixed(2),
        prepayment: prepayment.toFixed(2),
        interest: interest.toFixed(2),
        totalPayment: payment.toFixed(2),
        balance: remainingBalance.toFixed(2),
        loanPaidPercentage: loanPaidPercentage.toFixed(2),
      });
      
      currentDate.setMonth(currentDate.getMonth() + 1);
      
      if (remainingBalance <= 0.01) break;
    }
    
    return schedule;
  };

  const columns = [
    { 
      field: 'date', 
      headerName: 'Date', 
      width: 130,
      renderCell: (params) => {
        if (params.row.isGroup) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => toggleYearExpansion(params.row.date)}>
              {expandedYears[params.row.date] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <strong style={{ marginLeft: 5 }} onClick={() => toggleYearExpansion(params.row.date)}>Year {params.value}</strong>
            </div>
          );
        }
        return params.value;
      }
    },
    { 
      field: 'principal', 
      headerName: 'Principal', 
      width: 130,
      renderCell: (params) => {
        const value = Number(params.value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          maximumFractionDigits: 0
        });
        return params.row.isGroup ? <strong>{value}</strong> : value;
      }
    },
    { 
      field: 'prepayment', 
      headerName: 'Prepayment', 
      width: 130,
      renderCell: (params) => {
        const value = Number(params.value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          maximumFractionDigits: 0
        });
        return params.row.isGroup ? <strong>{value}</strong> : value;
      }
    },
    { 
      field: 'interest', 
      headerName: 'Interest', 
      width: 130,
      renderCell: (params) => {
        const value = Number(params.value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          maximumFractionDigits: 0
        });
        return params.row.isGroup ? <strong>{value}</strong> : value;
      }
    },
    { 
      field: 'totalPayment', 
      headerName: 'Total Payment', 
      width: 130,
      renderCell: (params) => {
        const value = Number(params.value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          maximumFractionDigits: 0
        });
        return params.row.isGroup ? <strong>{value}</strong> : value;
      }
    },
    { 
      field: 'balance', 
      headerName: 'Balance', 
      width: 130,
      renderCell: (params) => {
        return Number(params.value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          maximumFractionDigits: 0
        });
      }
    },
    { 
      field: 'loanPaidPercentage', 
      headerName: 'Loan Paid (%)', 
      width: 130,
      renderCell: (params) => {
        return `${params.value}%`;
      }
    }
  ];

  const calculateTotals = (schedule) => {
    return schedule.reduce((acc, row) => {
      return {
        principal: acc.principal + parseFloat(row.principal),
        interest: acc.interest + parseFloat(row.interest),
        prepayment: acc.prepayment + parseFloat(row.prepayment)
      };
    }, { principal: 0, interest: 0, prepayment: 0 });
  };

  const processScheduleWithYearGroups = (schedule) => {
    const yearGroups = {};
    const processedRows = [];
    let yearTotals = {};
  
    schedule.forEach(row => {
      const year = row.date.substring(0, 4);
      if (!yearGroups[year]) {
        yearGroups[year] = [];
        yearTotals[year] = {
          principal: 0,
          interest: 0,
          prepayment: 0,
          totalPayment: 0,
          balance: 0,
          loanPaidPercentage: 0,
        };
      }
  
      yearTotals[year].principal += parseFloat(row.principal);
      yearTotals[year].interest += parseFloat(row.interest);
      yearTotals[year].prepayment += parseFloat(row.prepayment);
      yearTotals[year].totalPayment += parseFloat(row.totalPayment);
      
      // Store December values for balance and loan paid percentage
      if (row.date.endsWith('-12')) {
        yearTotals[year].balance = parseFloat(row.balance);
        yearTotals[year].loanPaidPercentage = parseFloat(row.loanPaidPercentage);
      }
  
      yearGroups[year].push({
        ...row,
        id: `${year}-${row.id}`,
        path: [year]
      });
    });
  
    Object.keys(yearGroups).forEach(year => {
      processedRows.push({
        id: year,
        date: year,
        principal: yearTotals[year].principal.toFixed(2),
        interest: yearTotals[year].interest.toFixed(2),
        prepayment: yearTotals[year].prepayment.toFixed(2),
        totalPayment: yearTotals[year].totalPayment.toFixed(2),
        isGroup: true,
        balance: yearTotals[year].balance.toFixed(2), // December balance
        loanPaidPercentage: yearTotals[year].loanPaidPercentage.toFixed(2), // Loan paid percentage
        path: [year]
      });
  
      if (expandedYears[year]) {
        yearGroups[year].forEach(row => {
          processedRows.push({
            ...row,
            isGroup: false
          });
        });
      }
    });
  
    return processedRows;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'var(--text-primary)', fontWeight: 600 }}>
        Loan EMI Calculator with multiple prepayments
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={10} md={5}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Loan 
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Loan Amount"
                  type="number"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Interest Rate (%)"
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Loan Tenure (months)"
                  type="number"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Loan Start Date"
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Partial Payments
            </Typography>
            
            {/* Recurring Payments Section */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>Enable Recurring Prepayments</InputLabel>                
                <FormControl fullWidth>
                  <Select
                    value={enableRecurring}
                    onChange={(e) => setEnableRecurring(e.target.value)}
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {enableRecurring && (
                <Grid item xs={12}>
                  {recurringPayments.map((payment, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={`Start Date ${index + 1}`}
                            value={payment.startDate}
                            onChange={(newDate) => {
                              const newPayments = [...recurringPayments];
                              newPayments[index].amount = newDate;
                              setRecurringPayments(newPayments);
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                        </LocalizationProvider>
                      </Grid>                      
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label={`Amount ${index + 1}`}
                          type="number"
                          value={payment.amount}
                          onChange={(e) => {
                            const newPayments = [...recurringPayments];
                            newPayments[index].amount = Number(e.target.value);
                            setRecurringPayments(newPayments);
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <InputLabel>Frequency</InputLabel>
                          <Select
                            value={payment.frequency}
                            onChange={(e) => {
                              const newPayments = [...recurringPayments];
                              newPayments[index].frequency = e.target.value;
                              setRecurringPayments(newPayments);
                            }}
                          >
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Quarterly">Quarterly</MenuItem>
                            <MenuItem value="Yearly">Yearly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            const newPayments = recurringPayments.filter((_, i) => i !== index);
                            setRecurringPayments(newPayments);
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setRecurringPayments([...recurringPayments, { 
                        amount: 1000,
                        frequency: 'Monthly',
                        startDate: new Date()
                      }]);
                    }}
                  >
                    Add Recurring Payment
                  </Button>
                </Grid>
              )}

              {/* Adhoc Payments Section */}
              <Grid item xs={12}>
                <InputLabel>Enable Adhoc Prepayments</InputLabel>                
                <FormControl fullWidth>
                  <Select
                    value={enableAdhoc}
                    onChange={(e) => setEnableAdhoc(e.target.value)}
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {enableAdhoc && (
                <Grid item xs={12}>
                  {adhocPayments.map((payment, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={`Payment Date ${index + 1}`}
                            value={payment.date}
                            onChange={(newDate) => {
                              const newPayments = [...adhocPayments];
                              newPayments[index].date = newDate;
                              setAdhocPayments(newPayments);
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label={`Amount ${index + 1}`}
                          type="number"
                          value={payment.amount}
                          onChange={(e) => {
                            const newPayments = [...adhocPayments];
                            newPayments[index].amount = Number(e.target.value);
                            setAdhocPayments(newPayments);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => {
                            const newPayments = adhocPayments.filter((_, i) => i !== index);
                            setAdhocPayments(newPayments);
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setAdhocPayments([...adhocPayments, { date: new Date(), amount: 1000 }]);
                    }}
                  >
                    Add Payment
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" onClick={generateSchedule} fullWidth>
            Calculate
          </Button>
        </Grid>

        {schedule && originalSchedule && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    Impact of Prepayments
                  </Typography>
                  <Typography variant="body1" color="var(--accent-success)">
                    {originalSchedule.length - schedule.length} months reduced from original loan tenure
                  </Typography>
                  <Typography variant="body2" color="var(--text-secondary)">
                    Original: {originalSchedule.length} months | New: {schedule.length} months
                  </Typography>
                </Paper>
              </Grid>
            )}
            
        {schedule && (
          <>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Total Payment Distribution
                </Typography>
                <div style={{ position: 'relative', width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <text
                        x="12%"
                        y="50%"
                        textAnchor="left"
                        dominantBaseline="middle"
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          fill: 'var(--text-primary)'
                        }}
                      >
                        Overall Payments
                      </text>
                      <Pie
                        data={(() => {
                          const totals = calculateTotals(schedule);
                          const total = totals.principal + totals.interest + totals.prepayment;
                          return [
                            { 
                              name: 'Principal', 
                              value: (totals.principal/total) * 100,
                              actualValue: totals.principal.toLocaleString('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                                maximumFractionDigits: 0
                              })
                            },
                            { 
                              name: 'Interest', 
                              value: (totals.interest/total) * 100,
                              actualValue: totals.interest.toLocaleString('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                                maximumFractionDigits: 0
                              })
                            },
                            { 
                              name: 'Prepayments', 
                              value: (totals.prepayment/total) * 100,
                              actualValue: totals.prepayment.toLocaleString('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                                maximumFractionDigits: 0
                              })
                            }
                          ];
                        })()}
                        cx="35%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        dataKey="value"
                      >
                        <Cell fill="#6B8AFF" /> {/* Primary color for Principal */}
                        <Cell fill="#FFB547" /> {/* Warning color for Interest */}
                        <Cell fill="#42D7A0" /> {/* Success color for Prepayments */}
                      </Pie>
                      <Tooltip 
                        formatter={(value) => `${value.toFixed(1)}%`} 
                      />
                      <Legend 
                        align="right"
                        verticalAlign="middle"
                        layout="vertical"
                        iconType="circle"
                        wrapperStyle={{
                          right: 10,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          lineHeight: '24px'
                        }}
                        formatter={(value, entry) => {
                          return (
                            <span style={{ display: 'inline-block', marginLeft: '10px', color: 'var(--text-primary)' }}>
                              {`${value} (${entry.payload.actualValue})`}
                            </span>
                          );
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Loan Balance Over Time
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={schedule}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis 
                      tickFormatter={(value) => 
                        Number(value).toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                          maximumFractionDigits: 0,
                          notation: 'compact'
                        })
                      }
                      domain={[0, 'auto']}
                    />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="balance" stroke="#6B8AFF" />
                  </LineChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Payment Breakdown Over Time
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={schedule}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="principal" stroke="var(--primary-color)" strokeWidth={2} />
                    <Line type="monotone" dataKey="interest" stroke="var(--accent-warning)" strokeWidth={2} />
                    <Line type="monotone" dataKey="prepayment" stroke="var(--accent-success)" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Amortization Schedule
                </Typography>
                <DataGrid 
                  rows={processScheduleWithYearGroups(schedule)} 
                  columns={columns}
                  onRowClick={(params) => {
                    if (params.row.isGroup) {
                      toggleYearExpansion(params.row.date);
                    }
                  }}
                  treeData
                  getTreeDataPath={(row) => row.path}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'asc' }],
                    },
                  }}
                  autoHeight
                  pagination={false}
                  hideFooter={true}
                  disableSelectionOnClick
                  disableColumnMenu
                  sx={{
                    '& .MuiDataGrid-cell--group': {
                      backgroundColor: 'var(--background-main)',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: 'var(--text-secondary)',
                      },
                    },
                    '& .MuiDataGrid-row': {
                      backgroundColor: 'var(--background-paper)',
                    }
                  }}
                />
              </Paper>
            </Grid>
            
          </>
        )}

        <Grid item xs={12}>
          <Paper sx={{ p: 2, mt: 2, textAlign: 'center' }}>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'var(--text-secondary)',
                '& a': {
                  color: 'var(--text-secondary)',
                  textDecoration: 'none',
                  '&:hover': {
                    color: 'var(--text-primary)'
                  }
                }
              }}
            >
              Contact:{' '}
              <a href="mailto:omuthu.personal@gmail.com">
                omuthu.personal@gmail.com
              </a>
            </Typography>
          </Paper>
        </Grid>

      </Grid>
    </Container>

    
  );
}

export default App;

